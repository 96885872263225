<template>
  <div class="xl:mt-6 overflow-y-auto data-list-container main-list-view ml-6 table-announcement">
    <div
      v-if="!isFetching"
      class="">
      <div class="mt-6">
        <vue-cal
          :selected-date="selectedDate"
          :time-from="7 * 60"
          :time-to="24 * 60"
          :disable-views="['years']"
          :active-view="activeView"
          events-on-month-view="short"
          :events="items"
          @ready="calReady($event)"
          @view-change="logEvents($event)"
          @cell-click="onDayClick"
          :on-event-click="onEventClick"
					today-button
          style="height: 100%">
        </vue-cal>
      </div>
      <!-- modal detail -->
      <vs-prompt
        class="event-dialog"
        :title="`${$t('Date Details')}, ${modalDay}`"
        @cancel="isShowModalDetail = false"
        :cancel-text="$t('Cancel')"
        :buttons-hidden="true"
        :disabled-outside="true"
        :active.sync="isShowModalDetail">
          <div class="container-day-detail p-6">
            <div v-for="event in modalDetailEvent" :key="event.id">
							<div @mouseover="itemHover(event)" @mouseleave="itemLeave(event)" class="pointer relative" :class="`day-event-detail`">
								<div class="title">{{ event && event.additional && event.additional.name }}</div>
								<div class="mt-1">{{ event && event.additional.location ? event.additional.location : ''  }}</div>
								<div class="mt-1 font-light">{{ event && event.start && event.start ? event.start : ''  }} - {{ event && event.end && event.end ? event.end : ''  }}</div>
								<div class="icon-detail-day d-flex" v-if="hoverItem === event.id">
                  <button
										class="vs-button vs-button-secondary vs-button-filled btn-icon mr-2 p-0 m-0"
                    style="width: 36px; height: 36px;"
										@click="goToRundownSettings(event.additional)">
										<svg style="width: 24px; height: 24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M3 17.25V21H6.75L17.81 9.93L14.06 6.18L3 17.25M22.61 18.36L18.36 22.61L13.16 17.41L14.93 15.64L15.93 16.64L18.4 14.16L19.82 15.58L18.36 17L19.42 18L20.84 16.6L22.61 18.36M6.61 10.83L1.39 5.64L5.64 1.39L7.4 3.16L4.93 5.64L6 6.7L8.46 4.22L9.88 5.64L8.46 7.05L9.46 8.05L6.61 10.83M20.71 7C21.1 6.61 21.1 6 20.71 5.59L18.37 3.29C18 2.9 17.35 2.9 16.96 3.29L15.12 5.12L18.87 8.87L20.71 7Z" />
                    </svg>
									</button>
									<button
										class="vs-button vs-button-secondary vs-button-filled btn-icon mr-2 p-0 m-0"
                    style="width: 36px; height: 36px;"
										@click="goToRundown(event.additional)">
										<i class="material-icons md-16">visibility</i>
									</button>
									<button
										class="vs-button vs-button-secondary vs-button-filled btn-icon mr-2 p-0 m-0"
                    style="width: 36px; height: 36px;"
										@click="confirmEdit(event.additional)">
										<i class="material-icons md-16">edit</i>
									</button>
									<button
										class="vs-button vs-button-danger vs-button-filled btn-icon p-0 m-0"
                    style="width: 36px; height: 36px;"
										@click="confirmRemoveItem(event.additional)">
										<i class="material-icons md-16">delete</i>
									</button>
								</div>
							</div>
						</div>
          </div>
      </vs-prompt>
      <!-- edit event -->
      <event-modal
        :isModalActive="isShowEditModal"
        :data="modalData"
        @closeModal="isShowEditModal = false"
        @add="insert"
      />
    </div>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import dayjs from 'dayjs';
import eventsApi from '@/api/event';

import utc from 'dayjs/plugin/utc';
import {
  getAxiosErrorMessage, duplicateVar,
} from '@/lib/helper';
import EventModal from '@/components/EventModal.vue';


dayjs.extend(utc);

export default {
  components: {
    VueCal,
    EventModal,
  },
  props: {
  },
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      totalItems: 0,
      orderBy: 'created_at',
      sortBy: 'desc',
      limit: 1000,
      items: [],
      isFetching: false,
      selectedDate: '',
      months: `[${dayjs(new Date()).format('MM')}]`,
      years: dayjs(new Date()).format('YYYY'),
      activeView: 'month',
      filterStatus: 'all',
      keyword: '',
      selectedDay: null,

      // modal detail
      isShowModalDetail: false,
      modalDetailEvent: [],
      modalDay: null,
      selectedItem: null,
      isShowEditModal: false,
      modalData: {},

      // hover
      hover: false,
      hoverItem: null,
    };
  },
  created() {
  },
  mounted() {
    this.fetchListCalendarView();
  },
  beforeUnmount() {},
  methods: {
    calReady(e) {
      const year = dayjs(e.startDate).format('YYYY');
      const startMonth = dayjs(e.startDate).format('M');
      const endMonth = dayjs(e.endDate).format('M');
      this.years = year;
      this.months = [];
      if (startMonth === endMonth) this.months = `[${startMonth}]`;
      if (startMonth !== endMonth) {
        this.months = [];
        this.months.push(startMonth);
        this.months.push(endMonth);
        this.months = JSON.stringify(this.months);
      }
      if (e.events.length > 0) {
        this.selectedDate = e.events[e.events.length - 1].start;
      } else if (e.events.length === 0) {
        this.selectedDate = e.startDate;
      }
    },
    logEvents(e) {
      const year = dayjs(e.startDate).format('YYYY');
      const startMonth = dayjs(e.startDate).format('M');
      const endMonth = dayjs(e.endDate).format('M');
      this.years = year;
      this.months = [];
      if (startMonth === endMonth) this.months = `[${startMonth}]`;
      if (startMonth !== endMonth) {
        this.months = [];
        this.months.push(startMonth);
        this.months.push(endMonth);
        this.months = JSON.stringify(this.months);
      }
      if (e.events.length > 0) {
        this.selectedDate = e.events[e.events.length - 1].start;
      } else if (e.events.length === 0) {
        this.selectedDate = e.startDate;
      }
      this.fetchListCalendarView(e.view);
    },
    fetchListCalendarView(view) {
      if (view) this.activeView = view;
      this.isFetching = true;
      const params = {
        order_by: this.orderBy,
        sort_by: this.sortBy,
        limit: this.limit,
        page: this.currentPage,
        years: this.years,
        months: this.months,
      };
      if (this.keyword) params.keyword = this.keyword;
      const callback = (response) => {
        const data = response.data;
        this.totalPage = response.last_page;
        this.totalItems = response.total;
        this.items = [];
        for (let index = 0; index < data.length; index++) {
          const event = data[index];
          if (event && event.start_date) {
            const startHour = dayjs.utc(event.start_date).format('HH:mm');
            const startTime = dayjs.utc(event.start_date).format('YYYY-MM-DD');
            const endHour = dayjs.utc(event.end_date).format('HH:mm');
            const endDate = dayjs.utc(event.end_date).format('YYYY-MM-DD');
            const eventResult = {
              start: `${startTime} ${startHour}`,
              end: `${endDate} ${endHour}`,
              // message: element.message,
              title: `<div style="font-size: 13px; font-weight: 400; max-width: 200px; white-space: nowrap; overflow: hidden; overflow: hidden; text-overflow: ellipsis;"> ${event.name} </div>`,
              id: event.id,
              additional: event,
              // status: event.status,
              class: 'event-kobla',
            };
            // if (event.status === 'pre_production') eventResult.class = 'event-pre_production';
            // if (event.status === 'production') eventResult.class = 'event-production';
            // if (event.status === 'post_production') eventResult.class = 'event-post_production';
            // if (event.status === 'archive') eventResult.class = 'event-archive';
            this.items.push(eventResult);
          }
        }
        if (view === 'month' && this.items.length > 0) {
          this.selectedDate = this.items[this.items.length - 1].start;
        }
        this.isFetching = false;
      };
      const errorCallback = (error) => {
        const message = error.response.data.message;
        this.__showNotif('error', 'Error', message);
        this.isFetching = false;
      };
      eventsApi.getList(params, callback, errorCallback);
    },
    onEventClick(event) {
      const newDate = (event.start).format('YYYY-MM-DD');
      const items = this.items.filter(item => item.start.includes(newDate));
      if (items && items.length > 0) {
        this.isShowModalDetail = true;
        this.modalDetailEvent = items;
        this.modalDay = dayjs.utc(event.start).format('dddd, DD MMMM YYYY');
      }
    },
    onDayClick(event) {
      const newDate = dayjs.utc(event).format('YYYY-MM-DD');
      const items = this.items.filter(item => item.start.includes(newDate));
      if (items && items.length > 0) {
        this.isShowModalDetail = true;
        this.modalDetailEvent = items;
        this.modalDay = dayjs.utc(event).format('dddd, DD MMMM YYYY');
      }
    },
    itemLeave() {
      this.hover = false;
      this.hoverItem = null;
    },
    itemHover(item) {
      this.hover = true;
      this.hoverItem = item.id;
    },
    goToRundownSettings(item) {
      this.$router.push({ name: 'EventDetails', params: { slug: item.slug } });
    },
    goToRundown(event) {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: event && event.link_code_value ? event.link_code_value : null,
        },
      });
      window.open(routeData.href, '_blank');
    },
    confirmEdit(item) {
      this.modalData = duplicateVar(item);
      this.isShowEditModal = true;
      this.isShowModalDetail = false;
    },
    confirmRemoveItem(item) {
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} "${item.name}"?`,
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
      });
      this.isShowModalDetail = false;
    },
    deleteRecord() {
      this.$vs.loading();
      const notifTitle = this.$t('Event');
      const item = this.selectedItem;
      const callback = (response) => {
        const index = this.items.findIndex(({ id }) => id === item.id);
        if (index !== -1) {
          this.items.splice(index, 1);
        }
        const indexDayDetail = this.modalDetailEvent.findIndex(({ id }) => id === item.id);
        if (index !== -1) {
          this.modalDetailEvent.splice(indexDayDetail, 1);
        }

        if (this.modalDetailEvent && this.modalDetailEvent.length === 0) this.isShowModalDetail = false;
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      eventsApi.delete(item.id, callback, errorCallback);
    },
    insert(item) {
      const itemRundown = item;
      const startHour = dayjs.utc(itemRundown.start_date).format('HH:mm');
      const startDate = dayjs.utc(itemRundown.start_date).format('YYYY-MM-DD');
      const endHour = dayjs.utc(itemRundown.end_date).format('HH:mm');
      const endDate = dayjs.utc(itemRundown.end_date).format('YYYY-MM-DD');
      const index = this.items.findIndex(({ id }) => id === item.id);
      if (index !== -1) {
        const newItem = {
          start: `${startDate} ${startHour}`,
          end: `${endDate} ${endHour}`,
          // message: element.message,
          title: `<div style="font-size: 13px; font-weight: 400; max-width: 200px; white-space: nowrap; overflow: hidden; overflow: hidden; text-overflow: ellipsis;"> ${itemRundown.name} </div>`,
          id: itemRundown.id,
          additional: itemRundown,
          class: 'event-kobla',
        };
        this.$set(this.items, index, newItem);
      }
      const indexDayDetail = this.modalDetailEvent.findIndex(({ id }) => id === item.id);
      if (index !== -1) {
        const newItem = {
          start: `${startDate} ${startHour}`,
          end: `${endDate} ${endHour}`,
          // message: element.message,
          title: `<div style="font-size: 13px; font-weight: 400; max-width: 200px; white-space: nowrap; overflow: hidden; overflow: hidden; text-overflow: ellipsis;"> ${itemRundown.name} </div>`,
          id: itemRundown.id,
          additional: itemRundown,
          class: 'event-kobla',
        };
        this.$set(this.modalDetailEvent, indexDayDetail, newItem);

      //   // const previousDate = dayjs.utc(item.start_date).format('YYYY-MM-DD');
      //   // if (!previousDate.includes(startDate)) {
      //   //   this.modalDetailEvent.splice(indexDayDetail, 1);
      //   //   if (this.modalDetailEvent && this.modalDetailEvent.length === 0) this.isShowModalDetail = false;
      //   // }
      }
    },
  },
  computed: {
  },
  watch: {
    activeView() {
      if (this.activeView === 'year') {
        this.selectedDate = new Date();
      }
    },
  },
};
</script>
